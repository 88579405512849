import React, { Component } from 'react';
import './nav.css';

class Nav extends Component {
  render() {
    return (
      <nav>
        <ul>
          <li className="navigation"><img src={require('../images/Logo_s.png')} width="30%" height="30%"/>IBERCON</li>
        </ul>
        <ul>
          <li><a href="#productos">Productos</a></li>
          <li><a href="#donde"><p>Donde Estamos</p></a></li>
          <li><a href="#contacto">Contacto</a></li>
        </ul>
      </nav>
    )
  }
}

export default Nav;