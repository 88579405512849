import React, { Component } from 'react'
import './main.css'
import './form.css'
import Form from "./form";
import GoogleMapReact from 'google-map-react';

const K_WIDTH = 200;
const K_HEIGHT = 20;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '5px solid orange',
  borderRadius: K_HEIGHT,
  backgroundColor: 'white',
  textAlign: 'center',
  color: 'orange',
  fontSize: 14,
  padding: 4
};

const Marker = ({ text }) => <div style={greatPlaceStyle}>{text}</div>;

class Main extends Component {

  static defaultProps = {
    center: {
      lat: 40.4980004,
      lng: -3.8864066
    },
    zoom: 16
  };

  render() {
    return (
        <main>
            <section id="productos" className="about">
                <h2>Ibercon</h2>
                <div>
                  <p>Todo tipo de productos de ferretería industrial. Una amplísima gama de productos de las mejores marcas y a los mejores precios a tu disposición. ¡Descúbrelos!</p>
                  <p>Material de ferretería, maquinaria manual y pesada, eslingas, vestuario laboral, elementos de seguridad, impermeabilizantes, pegamentos y masillas; herramientas manuales y de jardín, discos y muchas variedades de tornillería. También trabajamos bajo pedido.</p>
                </div>
            </section>

            <div>
                <div className="services">
                  <div className="service__one">
                  <p className="service__icon"><img src={require('../images/screw-1052508_640.jpg')} width="200px" height="300px"></img></p>
                  <p className="service__title">Ferreteria</p>
                  </div>
                  <div className="service_two">
                  <p className="service__icon"><img src={require('../images/tools-15539_640.jpg')} width="200px" height="300px"></img></p>
                  <p className="service__title">Herramientas</p>
                  </div>
                  <div className="service__three">
                  <p className="service__icon"><img src={require('../images/industrial-1636390_640.jpg')} width="200px" height="300px"></img></p>
                  <p className="service__title">Protección Laboral</p>
                  </div>
                </div>
                <div className="services">
                  <div className="service__one">
                  <p className="service__icon"><img src={require('../images/house-keys-4521071_640.jpg')} width="200px" height="300px"></img></p>
                  <p className="service__title">Cerrajería</p>
                  </div>
                  <div className="service_two">
                  <p className="service__icon"><img src={require('../images/welding-67640_640.jpg')} width="200px" height="300px"></img></p>
                  <p className="service__title">Construcción</p>
                  </div>
                  <div className="service__three">
                  <p className="service__icon"><img src={require('../images/hose-pipe-1536413_640.jpg')} width="200px" height="300px"></img></p>
                  <p className="service__title">Jardinería</p>
                  </div>
                </div>
            </div>

            <div id="portofolio" className="gallery">
              <div className="gallery__item__one"></div>
              <div className="gallery__item__two"></div>
              <div className="gallery__item__three"></div>
              <div className="gallery__item__four"></div>
              <div className="gallery__item__five"></div>
              <div className="gallery__item__six"></div>
            </div>

            <section id="donde">
              <h2>Donde Estamos</h2>
              <div>
                <p>​Las Rozas - C/Berlín, Nº 2-A<br></br>
                Tel. 917105229<br></br>
                ferreteriaibercon@gmail.com</p>

                <p><strong>Horario:</strong><br></br>
                     Lunes a Jueves  8:30 a 14:00 - 15:30 a 19:00<br></br>
                     Viernes  8:30 a 14:00</p>
              </div>
              
            </section>
            <div style={{ height: '500px', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyA5VD9HPjCHvNDCNJhlgPDBwvl1xsBXrys' }}
                  defaultCenter={this.props.center}
                  defaultZoom={this.props.zoom}
                >
                  <Marker
                    lat={40.49794425863639}
                    lng={-3.8861405832182587}
                    text="Ferretería Industrial Ibercon"
                  />
                </GoogleMapReact>
              </div>
            <section id="contacto">
              <h2 className="form__h2">Contacto</h2>
              <Form />
            </section>
        </main>
    )
  }
}

export default Main;