import React, { Component } from 'react';
import Nav from './nav';
import './header.css';

class Header extends Component {
  render() {
    return (
      <header>
        <Nav />
          <div className="top">
              <h1 style={{color:'white'}}>Ferretería Industrial</h1>
              <div>
                  <div><a href="#contact" className="contact">Contacta con nosotros</a></div>
              </div>
          </div>
      </header>
    )
  }
}

export default Header;