import React, { Component } from 'react';
import './footer.css'

class Footer extends Component {
  render() {
    return (
      <footer>
        <h3><img src={require('../images/logo.jpeg')} width="100%" height="100%"/></h3>
      </footer>
    )
  }
}

export default Footer;